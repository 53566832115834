import axios from "@/core/services";

const state = {
  oaEmpList: [],
  comList: [],
  companyList: [],
  positionList: []
};
const mutations = {
  loadOaEmpList(state, args) {
    state.oaEmpList = [];
    axios
      .fetchPost("kybOa/loadOaEmpList", args)
      .then(data => {
        state.oaEmpList = data.data;
      })
      .catch(err => {
        console.log("调用失败" + err);
      });
  },
  setCompanyList(state, args) {
    state.companyList = [];
    axios
      .fetchPost("kybOa/getCompanyList", args)
      .then(data => {
        if (data && data.data) {
          state.companyList = data.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getAllPositionList(state, args) {
    state.positionList = [];
    axios
      .fetchPost("kybOa/getAllPositionList", args)
      .then(data => {
        if (data && data.data) {
          state.positionList = data.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  setComList(state, args) {
    state.comList = [];
    axios
      .fetchPost("kybOa/getComList", args)
      .then(data => {
        if (data && data.data) {
          state.comList = data.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
};

const actions = {
  loadOaEmpList(context, args) {
    context.commit("loadOaEmpList", args);
  },
  setCompanyList(context, args) {
    context.commit("setCompanyList", args);
  },
  getAllPositionList(context, args) {
    context.commit("getAllPositionList", args);
  },
  setComList(context, args) {
    context.commit("setComList", args);
  }
};

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
