import axios from "axios";
import Vue from "vue";
import store from "@/core/services/store";
Vue.prototype.url = "http://119.23.44.146:85/";
axios.defaults.timeout = 3600000; // 响应时间
axios.defaults.baseURL = Vue.prototype.url; // 配置接口地址
axios.defaults.withCredentials = false; // 关闭缓存
const common = axios.create({
  headers: { "Content-Type": "application/json;charset=UTF-8" } // 配置请求头
});
common.interceptors.request.use(
  config => {
    if (store.getters.currentUser && store.getters.currentUser.defaultDb) {
      config.params = {
        ...config.params,
        defaultDb: store.getters.currentUser.defaultDb
      };
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
const from = axios.create({
  headers: { "Content-Type": "multipart/form-data" } // 配置请求头
});
from.interceptors.request.use(
  config => {
    if (store.getters.currentUser && store.getters.currentUser.defaultDb) {
      config.params = {
        ...config.params,
        defaultDb: store.getters.currentUser.defaultDb
      };
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
export function fetchPost(url, params) {
  return new Promise((resolve, reject) => {
    params = JSON.stringify(params);
    common
      .post(url, params)
      .then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      )
      .catch(error => {
        reject(error);
      });
  });
}

export function formPost(url, params) {
  return new Promise((resolve, reject) => {
    from
      .post(url, params)
      .then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      )
      .catch(error => {
        reject(error);
      });
  });
}

export function fetchPostArray(url, params) {
  axios.defaults.responseType = "arraybuffer";
  const commonArray = axios.create({
    headers: { "Content-Type": "application/json;charset=UTF-8" } // 配置请求头
  });
  return new Promise((resolve, reject) => {
    params = JSON.stringify(params);
    commonArray
      .post(url, params)
      .then(
        response => {
          resolve(response);
        },
        err => {
          reject(err);
        }
      )
      .catch(error => {
        reject(error);
      });
  });
}

export default {
  fetchPost,
  formPost,
  fetchPostArray
};
