import axios from "@/core/services";
const state = {
  dayThingList: [],
  handEmpList: []
};
const mutations = {
  getDayThingList(state, args) {
    state.dayThingList = [];
    axios
      .fetchPost("kybDayThing/getDayThingList", args)
      .then(data => {
        if (data && data.data) {
          state.dayThingList = data.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getDayThingHandOver(state, args) {
    state.handEmpList = [];
    axios
      .fetchPost("kybHr/getDayThingHandOver", args)
      .then(data => {
        if (data && data.data) {
          state.handEmpList = data.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
};
const actions = {
  getDayThingList(context, args) {
    context.commit("getDayThingList", args);
  },
  getDayThingHandOver(context, args) {
    context.commit("getDayThingHandOver", args);
  }
};
const getters = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
