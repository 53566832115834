import axios from "@/core/services";
const state = {
  taskList: [],
  handEmpList: []
};
const mutations = {
  getTaskList(state, args) {
    axios
      .fetchPost("kybTask/getTaskList", args)
      .then(data => {
        if (data && data.data) {
          state.taskList = data.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTaskHandOver(state, args) {
    axios
      .fetchPost("kybHr/getTaskHandOver", args)
      .then(data => {
        if (data && data.data) {
          state.handEmpList = data.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
};
const actions = {
  getTaskList(context, args) {
    context.commit("getTaskList", args);
  },
  getTaskHandOver(context, args) {
    context.commit("getTaskHandOver", args);
  }
};
const getters = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
