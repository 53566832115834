import axios from "@/core/services";
const state = {
  work: {}
};
const mutations = {
  getWorkList: function(state, args) {
    axios
      .fetchPost("kybOa/getWorkList", args)
      .then(data => {
        if (data && data.data && data.data.length > 0) {
          const workList = data.data;
          state.work = {};
          state.work.dayThingList = [];
          state.work.kybAuditList = [];
          state.work.kybReportList = [];
          state.work.kybTaskList = [];
          state.work.noticeList = [];
          for (let obj of workList) {
            switch (obj.type) {
              // 日程安排
              case "1":
                state.work.dayThingList.push(obj);
                break;
              // 待办审批
              case "2":
                state.work.kybAuditList.push(obj);
                break;
              // 未读报告
              case "3":
                state.work.kybReportList.push(obj);
                break;
              // 未完成任务
              case "4":
                state.work.kybTaskList.push(obj);
                break;
              // 通知公告
              case "5":
                state.work.noticeList.push(obj);
                break;
            }
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
};
const actions = {
  getWorkList(context, args) {
    context.commit("getWorkList", args);
  }
};
const getters = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
