import axios from "@/core/services";
const state = {
  auditList: [],
  handEmpList: [],
  expenseTypeList: [],
  erpCompanyList: [],
  supplychainComList: [],
  customerList: [],
  saleCompanyList:[],
};
const mutations = {
  getAuditList(state, args) {
    axios
      .fetchPost("kybAudit/getAuditList", args)
      .then(data => {
        if (data && data.data) {
          state.auditList = data.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getAuditHandOver(state, args) {
    axios
      .fetchPost("kybOa/getAuditHandOver", args)
      .then(data => {
        if (data && data.data) {
          state.handEmpList = data.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getExpenseTypeList(state) {
    axios
      .fetchPost("kybOa/getExpenseTypeList")
      .then(data => {
        if (data && data.data) {
          state.expenseTypeList = data.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getSupplychainApplyData(state) {
    axios
        .fetchPost("kybOa/getSupplychainApplyData")
        .then(data => {
          state.erpCompanyList = [];
          state.supplychainList = [];
          state.customerList = [];
          if (data && data.data) {
            state.customerList = data.data.customerList;
            if (
                data.data.groupCompanyList &&
                data.data.groupCompanyList.length > 0
            ) {
              for (let groupCompany of data.data.groupCompanyList) {
                if (groupCompany.supplyFlag) {
                  state.supplychainComList.push(groupCompany);
                } else {
                  state.erpCompanyList.push(groupCompany);
                }
              }
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
  },
    getSaleCompanyList(state) {
      axios
          .fetchPost("kybOa/getSaleCompanyList")
          .then(data => {
            state.saleCustomerList = [];
            if (data && data.data) {
              state.saleCompanyList = data.data;
            }
          })
          .catch(err => {
            console.log(err);
          });
    }
};
const actions = {
  getAuditList(context, args) {
    context.commit("getAuditList", args);
  },
  getAuditHandOver(context, args) {
    context.commit("getAuditHandOver", args);
  },
  getExpenseTypeList(context) {
    context.commit("getExpenseTypeList");
  },
  getSupplychainApplyData(context) {
    context.commit("getSupplychainApplyData");
  },
    getSaleCompanyList(context) {
    context.commit("getSaleCompanyList");
    },

};
const getters = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
