import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/auth",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/register")
        },
        {
          name: "editPsd",
          path: "/editPsd",
          component: () => import("@/view/pages/auth/editPsd")
        }
      ]
    },
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/task",
          name: "task",
          component: () => import("@/view/pages/task/task.vue")
        },
        {
          path: "/report",
          name: "report",
          component: () => import("@/view/pages/report/report.vue")
        },
        {
          path: "/dayThing",
          name: "dayThing",
          component: () => import("@/view/pages/dayThing/dayThing.vue")
        },
        {
          path: "/audit",
          name: "audit",
          component: () => import("@/view/pages/audit/audit.vue")
        },
        {
          path: "/addressBook",
          name: "addressBook",
          component: () => import("@/view/pages/addressBook/addressBook.vue")
        },
        {
          path: "/system",
          redirect: "/notice",
          component: () => import("@/view/pages/system/system.vue"),
          children: [
            {
              name: "accredit",
              path: "/accredit",
              component: () => import("@/view/pages/system/deail/accredit")
            },
            {
              name: "notice",
              path: "/notice",
              component: () => import("@/view/pages/system/deail/notice")
            },
            {
              name: "update",
              path: "/update",
              component: () => import("@/view/pages/system/deail/update")
            },
            {
              name: "wx",
              path: "/wx",
              component: () => import("@/view/pages/system/deail/wx")
            },
          ]
        },
        {
          path: "/logo",
          name: "logo",
          component: () => import("@/view/pages/person/logo.vue")
        },
        {
          path: "/message",
          name: "message",
          component: () => import("@/view/pages/person/message.vue")
        },
        {
          path: "/password",
          name: "password",
          component: () => import("@/view/pages/person/password.vue")
        },
        {
          path: "/phone",
          name: "phone",
          component: () => import("@/view/pages/person/phone.vue")
        },
      ]
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
