import JwtService from "@/core/services/jwt.service";
import axios from "@/core/services";
import router from "@/router";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    let user = state.user;
    if (!user || !user.recordId) {
      user = eval("(" + JwtService.getToken() + ")");
    }
    return user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(() => {
      const user = {};
      user.phone = credentials.name;
      user.userPwd = credentials.password;
      axios
        .fetchPost("hr/user/login", user)
        .then(result => {
          if (result.data.result == "success") {
            context.commit(SET_AUTH, result.data.user);
            router.push("/");
          } else {
            context.commit(SET_ERROR, [result.data.message]);
          }
        })
        .catch(err => {
          context.commit(SET_ERROR, ["调用失败" + err]);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
    router.push("/login");
  },
  [VERIFY_AUTH](context, user) {
    context.commit(SET_AUTH, user);
  },
  [UPDATE_USER](context) {
    let user = state.user;
    if (!user || !user.recordId) {
      user = eval("(" + JwtService.getToken() + ")");
    }
    if (user && user.recordId) {
      user.cloud = "1";
      axios
        .fetchPost("hr/user/getUser", user)
        .then(result => {
          context.commit(SET_AUTH, result.data);
        })
        .catch(err => {
          context.commit(SET_ERROR, ["调用失败" + err]);
        });
    } else {
      context.commit(PURGE_AUTH);
      router.push("/login");
    }
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
