import axios from "@/core/services";
const state = {
  reportList: [],
  handEmpList: []
};
const mutations = {
  getReportList(state, args) {
    axios
      .fetchPost("kybReport/getReportList", args)
      .then(data => {
        if (data && data.data) {
          state.reportList = data.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getReportHandOver(state, args) {
    axios
      .fetchPost("kybHr/getReportHandOver", args)
      .then(data => {
        if (data && data.data) {
          state.handEmpList = data.data;
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
};
const actions = {
  getReportList(context, args) {
    context.commit("getReportList", args);
  },
  getReportHandOver(context, args) {
    context.commit("getReportHandOver", args);
  }
};
const getters = {};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
