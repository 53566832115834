import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import axios from './core/services'

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

import 'daterangepicker/daterangepicker';
import 'daterangepicker/daterangepicker.css';
import 'bootstrap-select/dist/js/bootstrap-select';
import 'bootstrap-select/dist/css/bootstrap-select.css';

import {LOGOUT, VERIFY_AUTH} from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  if (!store.getters.isAuthenticated){
    if (to.path != "/login" && to.path != "/editPsd" && to.path != "/register"){
      // 前往登录页面
      router.push("/login");
    }else {
      // 前往路由页面
      if (to.path != from.path){
        next();
      }
    }
  }else {
    // 二次验证用户数据
    if (store.getters.currentUser && store.getters.currentUser.recordId && store.getters.currentUser.groupManageId && store.getters.currentUser.defaultDb){
      const user = {};
      user.recordId = store.getters.currentUser.recordId;
      user.groupManageId = store.getters.currentUser.groupManageId;
      user.defaultDb = store.getters.currentUser.defaultDb;
      user.cloud = "1";
      axios.fetchPost("hr/user/getUser", user).then(result => {
        if(result.data.recordId && result.data.groupManageId && result.data.defaultDb){
          // 重新设置用户
          store.dispatch(VERIFY_AUTH, result.data);
          if (to.path == "/login"){
            router.push("/");
          }else {
            if (to.path != from.path){
              next();
            }
          }
        }else{
          // 退出，跳往登录页面
          store.dispatch(LOGOUT).then(() => router.push({ name: "login" }));
        }
      }).catch(() => {
        // 退出，提示请刷新重试
        store.dispatch(LOGOUT).then(() => router.push({ name: "login" }));
      });
    }else {
      store.dispatch(LOGOUT).then(() => router.push({ name: "login" }));
    }
  }
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
