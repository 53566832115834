import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import systemData from "@/core/services/store/system.data";

import taskStore from "./task/controller";
import reportStore from "./report/controller";
import dateTimeStore from "./dateTime/controller";
import dayThingStore from "./dayThing/controller";
import auditStore from './audit/controller'
import workStore from './work/controller'
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    systemData,

    taskStore,
    reportStore,
    dateTimeStore,
    dayThingStore,
    auditStore,
    workStore,
  }
});
