const state = {
  yearList: []
}
const mutations = {
  setYearList (state) {
    const list = []
    const date = new Date()
    const y = date.getFullYear()
    state.year = y
    for (let i = 0; i <= 10; i++) {
      const year = {}
      year.id = y - i
      year.name = y - i + '年'
      // 一年中的月
      const months = []
      for (let k = 0; k < 12; k++) {
        const mon = {}
        mon.id = k + 1
        mon.name = mon.id + '月'
        if (mon.id < 10) {
          mon.queryDate = year.id + '-0' + mon.id + '-01'
        } else {
          mon.queryDate = year.id + '-' + mon.id + '-01'
        }
        if (year.id === y && k === date.getMonth()) {
          state.month = k + 1
        }
        months.push(mon)
      }
      year.monthList = months

      // 一年第一个周一
      const firstDay = new Date(year.id, 0, 1)
      while (firstDay.getDay() !== 1) {
        firstDay.setDate(firstDay.getDate() + 1)
      }
      const to = new Date(year.id, 0, 1)
      while (to.getDay() !== 1) {
        to.setDate(to.getDate() + 1)
      }
      // 下一年的周一
      let lastDay = new Date(year.id + 1, 0, 1)
      while (lastDay.getDay() !== 1) {
        lastDay.setDate(lastDay.getDate() + 1)
      }
      // 保存周集合
      const weeks = []
      let j = 0
      for (let from = firstDay; from < lastDay;) {
        const week = {}
        week.id = j + 1
        week.name = '第' + week.id + '周'
        to.setDate(to.getDate() + 7)
        if (date > from && date < to) {
          state.week = week.id
        }
        // 计算每周的开始结束日期
        const startTime = year.id + '-' + ((from.getMonth() + 1) > 9 ? (from.getMonth() + 1) : '0' + (from.getMonth() + 1)) + '-' + (from.getDate() > 9 ? from.getDate() : '0' + from.getDate())
        let endTime = null
        let time = year.id + '年第' + week.id + '周 ' + (from.getMonth() + 1) + '月' + from.getDate() + '日 - '

        from.setDate(from.getDate() + 6)
        if (from < lastDay) {
          time += (from.getMonth() + 1) + '月' + from.getDate() + '日'
          endTime = year.id + '-' + ((from.getMonth() + 1) > 9 ? (from.getMonth() + 1) : '0' + (from.getMonth() + 1)) + '-' + (from.getDate() > 9 ? from.getDate() : '0' + from.getDate())
          if (startTime > endTime) {
            endTime = year.id + 1 + '-' + ((from.getMonth() + 1) > 9 ? (from.getMonth() + 1) : '0' + (from.getMonth() + 1)) + '-' + (from.getDate() > 9 ? from.getDate() : '0' + from.getDate())
          }
          from.setDate(from.getDate() + 1)
        } else {
          time += (lastDay.getMonth() + 1) + '月' + lastDay.getDate() + ''
          endTime = year.id + '-' + ((lastDay.getMonth() + 1) > 9 ? (lastDay.getMonth() + 1) : '0' + (lastDay.getMonth() + 1)) + '-' + (lastDay.getDate() > 9 ? lastDay.getDate() : '0' + lastDay.getDate())
          if (startTime > endTime) {
            endTime = year.id + 1 + '-' + ((lastDay.getMonth() + 1) > 9 ? (lastDay.getMonth() + 1) : '0' + (lastDay.getMonth() + 1)) + '-' + (lastDay.getDate() > 9 ? lastDay.getDate() : '0' + lastDay.getDate())
          }
          lastDay.setDate(lastDay.getDate() - 1)
        }
        week.time = time
        week.startTime = startTime
        week.endTime = endTime
        weeks.push(week)
        j++
      }
      year.weekList = weeks
      list.push(year)
    }
    state.yearList = list
  }
}
const actions = {
  setYearList (context, args) {
    context.commit('setYearList', args)
  }
}
const getters = {
}
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
